<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="pageBack">简历库</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">{{userName}} -- 沟通记录</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:5px">
            <div class="btnBox" style="margin-left:20px">
<!--              <el-button style="margin-left:20px" class="bgc-bv" round @click="getData()">查询</el-button>-->
              <el-button style="margin-left:20px" class="bgc-bv" round @click="showUpdateData()">新增沟通记录</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="table"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader4"

            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="100px"
                  :index="indexMethod"
              />
              <el-table-column
                  label="操作人"
                  align="left"
                  prop="operator"
                  show-overflow-tooltip
                  min-width="100"
              />
              <el-table-column
                  label="操作时间"
                  align="left"
                  prop="createTime"
                  show-overflow-tooltip
                  min-width="160"
              />
              <el-table-column
                  label="沟通结果"
                  align="left"
                  prop="contactResult"
                  show-overflow-tooltip
                  min-width="160"
              >
                <template slot-scope="{ row }">
                  <span>{{ $setDictionary("HR_CONTACT_RESULT", row.contactResult) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  label="操作内容"
                  align="left"
                  prop="remark"
                  show-overflow-tooltip
                  min-width="100"
              />
              <el-table-column label="操作" align="center" width="240px" fixed="right">
                <div slot-scope="{ row }">
                  <el-button type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="showUpdateData(row)"
                  >编辑</el-button>
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="deleteData(row.contactId)"
                  >删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="60%"
        :before-close="handleClose">
      <div>
<!--        <h3 style="padding: 0 0 20px 0;">沟通记录</h3>-->
        <el-form
            :model="dataForm"
            ref="dataForm"
            :rules="dataRules"
            label-width="150px"
        >
                <el-form-item label="沟通内容：" prop="remark">
                  <el-input
                      type="textarea"
                      :autosize="{ minRows: 6, maxRows: 10}"
                      v-model="dataForm.remark"
                      placeholder="请输入沟通内容"
                      maxlength="500"
                      show-word-limit>
                  </el-input>
                </el-form-item>
                <el-form-item label="沟通结果：" prop="contactResult">
                  <el-select
                      size="small"
                      v-model="dataForm.contactResult"
                      clearable
                  >
                    <el-option
                        v-for="item in contactResultList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>


        </el-form>
      </div>
      <span slot="footer" class="dialog-footer" style="width: 100%">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="saveData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "communicationRecord",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      talentId: this.$route.query.talentId,
      userName: this.$route.query.userName,

      areaList: [], // 期望城市List
      salaryRequireList: [], // 薪资要求List

      dialogTitle: '',
      dialogVisible: false, //新增编辑弹窗开关

      dataForm: {
        contactId: "",
        remark: "",
        contactResult: ""
      },

      contactResultList: [], // 项目角色List

      dataRules: {
        remark: [{ required: true, message: "请输入沟通内容", trigger: "blur" }],
        contactResult: [{ required: true, message: "请选择沟通结果", trigger: "change" }],
      },
      retrievalData:{},
      currentPage:''
    };
  },
  computed: {},
  created() {
    if(this.$route.query.retrievalData){
      this.retrievalData = this.$route.query.retrievalData
    }
    if(this.$route.query.currentPage){
      this.currentPage = this.$route.query.currentPage
    }
    this.getDropDownData()
  },
  methods: {
    // 返回上一页
    pageBack(){
      this.$router.push({
        path: "/azhr/humanResources/resumeLibrary/resumeList",
        query: {
          retrievalData: this.retrievalData,
          currentPage: this.currentPage
        },
      });
    },
    // 获取 all- 下拉数据
    getDropDownData() {
     // 沟通结果list
      const contactResultList = this.$setDictionary(
          "HR_CONTACT_RESULT",
          "list"
      );
      for (const key in contactResultList) {
        this.contactResultList.push({
          value: key,
          label: contactResultList[key],
        });
      }
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        talentId: this.talentId
      };
      this.doFetch({
        url: "/hr/talent/hr-contact-log/pageList",
        params,
        pageNum
      });
    },
    // 打开新增&编辑
    showUpdateData(row){
      this.dialogVisible = true
      let str = '沟通记录'
      this.$nextTick(()=>{
        this.$refs["dataForm"].resetFields();

        if(row){
          this.dialogTitle = '编辑' + str
          this.dataForm = {
            ...row
          }
          // this.dataForm.itemTime = [ row.beginTime.substring(0,10), row.endTime.substring(0,10) ]
          this.dataForm.contactId = row.contactId
        }else{
          this.dialogTitle = '新增' + str
          this.dataForm.contactId = ""
        }
      })
    },
    // 关闭新增&编辑
    handleClose() {
      this.dialogVisible = false
    },
    // 弹层保存数据
    saveData(){
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let url = '/hr/talent/hr-contact-log/insert'
          let params = {
            talentId: this.talentId,
            remark: this.dataForm.remark,
            contactResult: this.dataForm.contactResult,
          }
          if(this.dataForm.contactId != ""){
            params.contactId = this.dataForm.contactId
            url = '/hr/talent/hr-contact-log/modify'
          }
          console.log(params)
          this.$post(
              url,
              params
          ).then((res) => {
            if (res.status == 0) {
              this.$message({
                message: res.message,
                type: "success",
              });
              this.dialogVisible = false
              this.getData(-1);
            }
          });
        }
      })
    },
    // 删除列表数据
    deleteData(id){
      let str = '沟通记录'
      this.$confirm(
          "是否删除此条" + str,
          "提示",
          {
            confirmButtonText: "确定删除",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(() => {
            let params = {
              // talentId: this.talentId,
              contactId: id
            }
            console.log(params)
            this.$post(
                "/hr/talent/hr-contact-log/remove",
                params
            ).then((res) => {
              if (res.status == 0) {
                this.$message({
                  message: res.message,
                  type: "success",
                });
                this.getData(-1);
              }
            });
          })
          .catch(() => {
            return;
          });
    },

  },
  // beforeRouteLeave: resetKeepAlive,
  watch: {}
};
</script>
<style lang="less" scoped>
.el-rate__icon {
  margin-right: 0;
}


//人力资源样式重置
/deep/::-webkit-scrollbar-thumb{
  background-color: #eee;
  border-radius: 0px;
}
/deep/::-webkit-scrollbar-track-piece {
  background: #fff;
  border-radius: 0px;
}
/deep/.el-pager li{
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
/deep/.el-pagination.is-background .el-pager li{
  background-color: #fff;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #2878FF;
  border: 1px solid #2878FF;
}
/deep/.el-pagination.is-background .btn-next, /deep/.el-pagination.is-background .btn-prev{
  border: 1px solid #d9d9d9;
  background-color: #fff;
}
/deep/.el-pager li.active+li{
  border-left: 1px solid #d9d9d9;
}
/deep/.el-pagination.is-background .btn-next.disabled, /deep/.el-pagination.is-background .btn-next:disabled, /deep/.el-pagination.is-background /deep/.btn-prev.disabled, /deep/.el-pagination.is-background .btn-prev:disabled, /deep/.el-pagination.is-background .el-pager li.disabled {
  color: #C0C4CC;
  background-color: #f4f4f5;
  border: 1px solid #d9d9d9;
}
/deep/.el-pagination button{
  border: 1px solid #d9d9d9;
}
/deep/.el-button--text{
  //color: #2878FF;
  font-size: 14px!important;
}
/deep/.bgc-bv{
  background-color: #2878FF !important;
  color: #fff !important;
  border-color: #2878FF !important;
  border-radius: 0;
}
/deep/.el-table th.el-table__cell>.cell{
  font-size: 14px!important;
  font-weight: 400!important;
}
/deep/.el-dialog__header{
  background-color: #fff;
}
/deep/.el-dialog__header .el-dialog__title{
  color: #333;
}
/deep/.el-dialog__header .el-icon-close:before{
  color: #333;
}
/deep/.el-range-editor.is-active, /deep/.el-range-editor.is-active:hover, /deep/.el-select .el-input.is-focus .el-input__inner{
  border-color: #2878FF;
}
</style>
